<template>
	<a-space direction="vertical">
		<a-card title="查询条件">
			<a-form-model layout="inline" @submit.prevent="onSearch">
				<a-form-model-item label="商品类型">
					<a-select style="width: 200px" v-model="query.productType" placeholder="请选择商品类型" allow-clear>
						<a-select-option value="">全部</a-select-option>
						<a-select-option :value="1">汤为丽单品</a-select-option>
						<a-select-option :value="2">汤为丽礼包</a-select-option>
						<a-select-option :value="3">千年艾套盒</a-select-option>
						<a-select-option :value="4">零售产品</a-select-option>
						<a-select-option :value="5">店内辅材</a-select-option>
						<a-select-option :value="6">仪器耗材</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="商品状态">
					<a-select style="width: 200px" v-model="query.status" placeholder="请选择商品类型" allow-clear>
						<a-select-option value="">全部</a-select-option>
						<a-select-option :value="1">上架</a-select-option>
						<a-select-option :value="2">下架</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="商品名称">
					<a-input v-model="query.productName" placeholder="请输入商品名称"></a-input>
				</a-form-model-item>
				<a-form-model-item>
					<a-space>
						<a-button type="primary" html-type="submit">查询</a-button>
						<a-button @click="onReset">重置</a-button>
					</a-space>
				</a-form-model-item>
			</a-form-model>
		</a-card>

		<a-card title="商品管理">
			<a-button slot="extra" type="primary" @click="onCreate">新增</a-button>
			<a-table :data-source="lists.data" :pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }" @change="onPageChange" :scroll="{ x: 'max-content' }">
				<a-table-column key="id" title="ID" data-index="id" />
				<a-table-column key="productName" title="产品名称" data-index="productName" />
				<a-table-column key="smallPic" title="封面图" data-index="smallPic">
					<template slot-scope="smallPic">
						<a-avatar shape="square" :size="64" :src="smallPic" />
					</template>
				</a-table-column>
				<a-table-column key="banner" title="横幅图" data-index="banner">
					<template slot-scope="banner">
						<a-avatar shape="square" :size="64" :src="banner" style="width:114px;height:64px;" />
					</template>
				</a-table-column>
				<a-table-column key="productTypeStr" title="产品类型" data-index="productTypeStr" />
				<a-table-column key="salePrice" title="销售价" data-index="salePrice" />
				<a-table-column key="sort" title="排序" data-index="sort" />
				<a-table-column key="status" title="状态" data-index="status">
					<template slot-scope="status, record">
						<a-tag color="green" v-if="status == 1">{{ record.statusStr }}</a-tag>
						<a-tag color="red" v-else-if="status == 2">{{ record.statusStr }}</a-tag>
					</template>
				</a-table-column>
				<a-table-column key="action" title="操作" fixed="right">
					<template slot-scope="text, record">
						<a-space>
							<a-button size="small" @click="onView(record)">详情</a-button>
							<a-button size="small" @click="onEdit(record)">编辑</a-button>
						</a-space>
					</template>
				</a-table-column>
			</a-table>
		</a-card>
	</a-space>
</template>

<script>
	import moment from 'moment';
	import { mapActions, mapState } from 'vuex';
	import { OPERATION } from '@/utils/constants';
	import { parseQuery, numberFormat } from '@/utils';
	const DEFAULT_QUERY = {
		page: 1,
		size: 10,
		status: undefined,
		productType: undefined,
		productName: undefined
	};
	const formatter = 'YYYY-MM-DD';
	export default {
		components: {
			// IncCase: () => import("./inc/Case"),
		},
		data() {
			return {
				query: Object.assign({}, DEFAULT_QUERY),
				lists: {
					data: [],
					current_page: 1,
					per_page: 10,
					total: 0
				}
			};
		},
		mounted() {
			Object.keys(this.$route.query).forEach(key => {
				Object.assign(this.query, {
					[key]: parseInt(this.$route.query[key]) })
			})
			// Object.assign(this.query, this.$route.query);
			this.getGoods();
		},
		methods: {
			async getGoods() {
				const response = await this.$api.get('/goods_list', {
					params: this.query
				});
				if (response && response.code == 200) {
					response.data.data = response.data.data.map(v => {
						v.payUrls = String(v.payUrl).split(',');
						return v;
					});
					this.lists = Object.assign(this.lists, response.data);
					location.hash = this.$route.path + '?' + parseQuery(this.query);
				}
			},
			async onPageChange(pagination) {
				this.query.page = pagination.current;
				this.query.size = pagination.pageSize;
				this.getGoods();
			},
			async onSearch() {
				this.query.page = 1;
				this.getGoods();
			},
			async onReset() {
				this.query = Object.assign({}, DEFAULT_QUERY);
				this.getGoods();
			},
			async onCreate() {
				this.$router.push('/goods/create');
			},
			async onView(record) {
				this.$router.push(`/goods/${record.id}`);
			},
			async onEdit(record) {
				this.$router.push(`/goods/${record.id}/edit`);
			}
		}
	};
</script>